import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./index.css";
import "./bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import PersonalListRecordScreen from "./screens/PersonalListRecordScreen";
import PersonalConsonantListRecordScreen from "./screens/PersonalConsonantListRecordScreen";
import PersonalTypeListRecordScreen from "./screens/PersonalTypeListRecordScreen";
import PersonalRecordScreen from "./screens/PersonalRecordScreen";
import TeamRecordScreen from "./screens/TeamRecordScreen";
import TeamConsonantRecordScreen from "./screens/TeamConsonantRecordScreen";
import TeamTypeRecordScreen from "./screens/TeamTypeRecordScreen";
import TeamApplyScreen from "./screens/TeamApplyScreen";
import CompetitionListScreen from "./screens/CompetitionListScreen";
import YouthListScreen from "./screens/YouthListScreen";
import LeagueRoundListScreen from "./screens/LeagueRoundListScreen";
import DetailGameRecordScreen from "./screens/DetailGameRecordScreen";
import CommunityListScreen from "./screens/CommunityListScreen";
import CommunityReadScreen from "./screens/CommunityReadScreen";
import CommunityWriteScreen from "./screens/CommunityWriteScreen";
import MyPageScreen from "./screens/MyPageScreen";
import LoginScreen from "./screens/LoginScreen";
import IDEmailFindScreen from "./screens/IDEmailFindScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import TempPlayerInputScreen from "./adminscreens/TempPlayerInputScreen";
import TempTeamInputScreen from "./adminscreens/TempTeamInputScreen";
import ManageUserListScreen from "./screens/ManageUserListScreen";
import ModifyPlayerScreen from "./adminscreens/ModifyPlayerScreen";
import NoticeInputScreen from "./adminscreens/NoticeInputScreen";
import CompetitionNoticeInputScreen from "./adminscreens/CompetitionNoticeInputScreen";
import ModifyCompetitionImageScreen from "./adminscreens/ModifyCompetitionImageScreen";
import ModifyTeamScreen from "./adminscreens/ModifyTeamScreen";
import ModifyCompetitionScreen from "./adminscreens/ModifyCompetitionScreen";

import DefaultScreen from "./screens/DefaultScreen";
import TeamDetailScreen from "./screens/TeamDetailScreen";
import CompetitionRecordScreen from "./screens/CompetitionRecordScreen";
import RegisterScreen from "./screens/RegisterScreen";
import RegisterCompleteScreen from "./screens/RegisterCompleteScreen";
import TempCompetitionInputScreen from "./adminscreens/TempCompetitionInputScreen";

import TempGameInput from "./adminscreens/TempGameInput";
import DumbGameInput from "./adminscreens/DumbGameInput";
import ModifyGameScreen from "./adminscreens/ModifyGameScreen";

import GamePlayersManageScreen from "./teamleaderscreens/GamePlayersManageScreen";
import TeamManageScreen from "./teamleaderscreens/TeamManageScreen";

import CompetitionStatsScreen from "./adminscreens/CompetitionStatsScreen";

import LeagueRoundDetailScreen from "./screens/LeagueRoundDetailScreen";
import SearchScreen from "./screens/SearchScreen";

import ModifyVideoScreen from "./adminscreens/ModifyVideoScreen";
import PickupGameBookingListScreen from "./screens/PickupGameBookingListScreen";
import PickupGameBookingDetailScreen from "./screens/PickupGameBookingDetailScreen";
import PickupGameListScreen from "./screens/PickupGameListScreen";
import PickupGameBookingCreateScreen from "./pickupaccountscreens/PickupGameBookingCreateScreen";
import PickupGameTeamMakerScreen from "./pickupaccountscreens/PickupGameTeamMakerScreen";
import PickupGameInput from "./pickupaccountscreens/PickupGameInput";
import PickupGameRecordScreen from "./screens/PickupGameRecordScreen";
import DetailPickupGameRecordScreen from "./screens/DetailPickupGameRecordScreen";
import PickupPlayerSearchScreen from "./screens/PickupPlayerSearchScreen";
import PickupPlayerListScreen from "./screens/PickupPlayerListScreen";
import PickupPlayerRecordScreen from "./screens/PickupPlayerRecordScreen";

import VideoDetailScreen from "./screens/VideoDetailScreen";
import ModifyNoticeScreen from "./adminscreens/ModifyNoticeScreen";
import NoticeListScreen from "./screens/NoticeListScreen";
import NoticeDetailScreen from "./screens/NoticeDetailScreen";
import CompetitionNoticeListScreen from "./screens/CompetitionNoticeListScreen";
import CompetitionNoticeDetailScreen from "./screens/CompetitionNoticeDetailScreen";

import ModifyCompetitionNoticeScreen from "./associationscreens/ModifyCompetitionNoticeScreen";
import AllCompetitionNoticeListScreen from "./screens/AllCompetitionNoticeListScreen";
import RecordPrintScreen from "./adminscreens/RecordPrintScreen";
import NewTeamScreen from "./teamleaderscreens/NewTeamScreen";
import DivisionCompetitionListScreen from "./screens/DivisionCompetitionListScreen";
import DivisionRoundListScreen from "./screens/DivisionRoundListScreen";
import DivisionRoundDetailScreen from "./screens/DivisionRoundDetailScreen";
import AwardGenerateScreen from "./adminscreens/AwardGenerateScreen";
import RecordSwapScreen from "./adminscreens/RecordSwapScreen";

import CompetitionApplyScreen from "./screens/CompetitionApplyScreen";
import MatchMaker from "./adminscreens/MatchMaker";
import ArticleWriteScreen from "./adminscreens/ArticleWriteScreen";
import CompetitionNoticeWriteScreen from "./associationscreens/CompetitionNoticeWriteScreen";
import CompetitionManageScreen from "./associationscreens/CompetitionManageScreen";
import CompetitionTeamAcceptScreen from "./associationscreens/CompetitionTeamAcceptScreen";
import CompetitionGenerateScreen from "./associationscreens/CompetitionGenerateScreen";
import CompetitionGroupGameScreen from "./associationscreens/CompetitionGroupGameScreen";
import UserProfileScreen from "./screens/UserProfileScreen";
import MyTeamPageScreen from "./teamleaderscreens/MyTeamPageScreen";
import CompetitionOfficialListScreen from "./screens/CompetitionOfficialListScreen";
import ThreeOnThreeListRecordScreen from "./screens/ThreeOnThreeListRecordScreen";
import PersonalThreeOnThreeRecordScreen from "./screens/PersonalThreeOnThreeRecordScreen";
import ThreeOnThreeSearchScreen from "./screens/ThreeOnThreeSearchScreen";
import CompetitionSeasonListScreen from "./screens/CompetitionSeasonListScreen";
import CompetitionDivisionListScreen from "./screens/CompetitionDivisionListScreen";
import CompetitionSearchScreen from "./screens/CompetitionSearchScreen";
import CompetitionApplyListScreen from "./adminscreens/CompetitionApplyListScreen";
import CompetitionApplyDetailScreen from "./adminscreens/CompetitionApplyDetailScreen";
import CompetitionApplyFindScreen from "./screens/CompetitionApplyFindScreen";
import CompetitionApplyLinkScreen from "./adminscreens/CompetitionApplyLinkScreen";
import RecorderMainScreen from "./gamerecorder/RecorderMainScreen";


const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/personallistrecord" component={SearchScreen} exact />
          <Route
            path="/personallistrecord/page/:pageNumber"
            component={SearchScreen}
            exact
          />
          <Route
            path="/3on3listrecord/page/:pageNumber"
            component={ThreeOnThreeSearchScreen}
            exact
          />
          <Route
            path="/personallistrecord/search/:keyword/page/:pageNumber"
            component={PersonalListRecordScreen}
            exact
          />
          <Route
            path="/3on3listrecord/search/:keyword/page/:pageNumber"
            component={ThreeOnThreeListRecordScreen}
            exact
          />
          <Route
            path="/personallistrecord-c/search/:keyword/page/:pageNumber"
            component={PersonalConsonantListRecordScreen}
            exact
          />
          <Route
            path="/personallistrecord-t/search/:keyword/page/:pageNumber"
            component={PersonalTypeListRecordScreen}
            exact
          />
          <Route path="/player/:id" component={PersonalRecordScreen} />
          <Route path="/player-3on3/:id" component={PersonalThreeOnThreeRecordScreen} />
          <Route path="/teamrecord" component={TeamRecordScreen} exact />

          <Route
            path="/teamrecord-c/search/:keyword/page/:pageNumber"
            component={TeamConsonantRecordScreen}
            exact
          />

          <Route
            path="/teamrecord-t/search/:keyword/page/:pageNumber"
            component={TeamTypeRecordScreen}
            exact
          />

          <Route
            path="/teamrecord/page/:pageNumber"
            component={TeamRecordScreen}
            exact
          />

          <Route
            path="/teamrecord/search/:keyword"
            component={TeamRecordScreen}
            exact
          />

          <Route
            path="/teamrecord/search/:keyword/page/:pageNumber"
            component={TeamRecordScreen}
            exact
          />
          <Route path="/team/:id" component={TeamDetailScreen} />

          <Route path="/teamapply/:id" component={TeamApplyScreen} exact />

          <Route
            path="/teamapply/page/:pageNumber"
            component={TeamApplyScreen}
            exact
          />

          <Route
            path="/teamapply/search/:keyword"
            component={TeamApplyScreen}
            exact
          />

          <Route
            path="/teamapply/search/:keyword/page/:pageNumber"
            component={TeamApplyScreen}
            exact
          />


          <Route
            path="/competitionrecord/:category/:teamType/page/:pageNumber"
            component={CompetitionOfficialListScreen}
            exact
          />

          <Route
            path="/competitionrecord/:category/season/:year/page/:pageNumber"
            component={CompetitionSeasonListScreen}
            exact
          />

          <Route
            path="/competitionrecord/:category/division/:division/page/:pageNumber"
            component={CompetitionDivisionListScreen}
            exact
          />

          <Route
            path="/competitionrecord-search/:keyword/page/:pageNumber"
            component={CompetitionSearchScreen}
            exact
          />

          <Route
            path="/leagueround/:id"
            component={LeagueRoundListScreen}
            exact
          />

          <Route
            path="/leagueround/:id/:round"
            component={LeagueRoundDetailScreen}
            exact
          />

          <Route
            path="/divisionround/:id"
            component={DivisionRoundListScreen}
            exact
          />

          <Route
            path="/dvisionround/:id/:round"
            component={DivisionRoundListScreen}
            exact
          />

          <Route
            path="/divisionround/:id/:round"
            component={DivisionRoundDetailScreen}
            exact
          />

          <Route
            path="/leaguerecord/:id"
            component={CompetitionRecordScreen}
            exact
          />
          <Route
            path="/gamerecord/detail/:id"
            component={DetailGameRecordScreen}
          />

          <Route
            path="/admin/videomodify/:id"
            component={ModifyVideoScreen}
            exact
          />
          <Route path="/videodetail/:id" component={VideoDetailScreen} exact />

          <Route path="/write-article/:id" component={ArticleWriteScreen} exact />

          <Route
            path="/admin/record-print/:id"
            component={RecordPrintScreen}
            exact
          />

          <Route
            path="/admin/div-award"
            component={AwardGenerateScreen}
            exact
          />

          <Route
            path="/admin/record-swap"
            component={RecordSwapScreen}
            exact
          />

          <Route path="/media" component={DefaultScreen} />
          <Route path="/ranking" component={DefaultScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/id-find" component={IDEmailFindScreen} />
          <Route
            path="/password-reset/:token"
            component={PasswordResetScreen}
          />

          <Route path="/register/:sid?" component={RegisterScreen} />
          <Route path="/welcome" component={RegisterCompleteScreen} />

          <Route path="/profile" component={UserProfileScreen} />
          <Route path="/mypage" component={MyPageScreen} />
          <Route path="/myteam" component={MyTeamPageScreen} />
          <Route path="/community" component={CommunityListScreen} exact />
          <Route path="/community/:id" component={CommunityReadScreen} exact />
          <Route path="/write-post" component={CommunityWriteScreen} exact />
          <Route
            path="/pickupgame/booking"
            component={PickupGameBookingListScreen}
            exact
          />

          <Route
            path="/pickupgame/booking/:id"
            component={PickupGameBookingDetailScreen}
            exact
          />

          <Route
            path="/pickupgame/recruit/"
            component={PickupGameBookingCreateScreen}
            exact
          />

          <Route
            path="/pickupgame/team-maker/:id"
            component={PickupGameTeamMakerScreen}
            exact
          />

          <Route
            path="/pickupgame/gameinput"
            component={PickupGameInput}
            exact
          />

          <Route
            path="/pickupgame/game-record"
            component={PickupGameListScreen}
            exact
          />

          <Route
            path="/pickupgame/game-record/:id"
            component={PickupGameRecordScreen}
            exact
          />

          <Route
            path="/pickupgame/game-record/detail/:id"
            component={DetailPickupGameRecordScreen}
            exact
          />

          <Route
            path="/pickupgame/player-record/page/:pageNumber"
            component={PickupPlayerSearchScreen}
            exact
          />

          <Route
            path="/pickupgame/player-record/search/:keyword/page/:pageNumber"
            component={PickupPlayerListScreen}
            exact
          />

          <Route
            path="/pickupgame/player/:id"
            component={PickupPlayerRecordScreen}
            exact
          />

          <Route
            path="/community/page/:pageNumber"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/search/:keyword"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/search/:keyword/page/:pageNumber"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/category/:category"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/category/:category/page/:pageNumber"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/search/:keyword/category/:category"
            component={CommunityListScreen}
            exact
          />

          <Route
            path="/community/search/:keyword/category/:category/page/:pageNumber"
            component={CommunityListScreen}
            exact
          />

          <Route path="/notice/list" component={NoticeListScreen} exact />
          <Route path="/notice/new" component={NoticeInputScreen} exact />
          <Route path="/notice/edit/:id" component={ModifyNoticeScreen} exact />
          <Route
            path="/notice/detail/:id"
            component={NoticeDetailScreen}
            exact
          />
          <Route
            path="/association/competition-manage"
            component={CompetitionManageScreen}
            exact
          />
          <Route
            path="/association/competition-manage/accept/:id"
            component={CompetitionTeamAcceptScreen}
            exact
          />
          <Route
            path="/association/competition-generate/:id"
            component={CompetitionGenerateScreen}
            exact
          />
          <Route
            path="/association/competition-group/:id"
            component={CompetitionGroupGameScreen}
            exact
          />
          <Route
            path="/competition-notice/new/:id"
            component={CompetitionNoticeInputScreen}
            exact
          />
          <Route
            path="/competition-notice/page/:pageNumber"
            component={CompetitionNoticeListScreen}
            exact
          />
          <Route
            path="/association/competition-notice/write"
            component={CompetitionNoticeWriteScreen}
            exact
          />
          <Route
            path="/competition-notice/:id"
            component={CompetitionNoticeDetailScreen}
            exact
          />
          <Route
            path="/competition-notice/edit/:id"
            component={ModifyCompetitionNoticeScreen}
            exact
          />
          <Route
            path="/competition-notice/list"
            component={AllCompetitionNoticeListScreen}
            exact
          />

          <Route path="/admin/gameinput" component={TempGameInput} />
          <Route path="/admin/dumbgameinput" component={DumbGameInput} />

          {/* <Route path="/admin/match-maker" component={MatchMaker} /> */}

          <Route path="/admin/gamemodify/:id" component={ModifyGameScreen} />

          <Route path="/player-list/:id" component={GamePlayersManageScreen} />

          <Route path="/playermanage" component={TeamManageScreen} />

          <Route
            path="/admin/tmpplayerinput"
            component={TempPlayerInputScreen}
          />
          <Route path="/admin/tmpteaminput" component={TempTeamInputScreen} />
          <Route path="/admin/user" component={ManageUserListScreen} exact />
          <Route
            path="/admin/user/page/:pageNumber"
            component={ManageUserListScreen}
            exact
          />
          <Route
            path="/admin/user/search/:keyword"
            component={ManageUserListScreen}
            exact
          />
          <Route
            path="/admin/user/search/:keyword/page/:pageNumber"
            component={ManageUserListScreen}
            exact
          />
          <Route
            path="/admin/competitioninput"
            component={TempCompetitionInputScreen}
            exact
          />

          <Route path="/admin/user/:id" component={ModifyPlayerScreen} />

          <Route
            path="/admin/competition-image"
            component={ModifyCompetitionImageScreen}
          />

          <Route
            path="/admin/competition/:id"
            component={ModifyCompetitionScreen}
          />
          <Route
            path="/admin/competition-apply/:id"
            component={CompetitionApplyListScreen}
            exact
          />
          <Route
            path="/admin/competition-apply/detail/:id/:readOnly"
            component={CompetitionApplyDetailScreen}
            exact
          />
          <Route path="/admin/team/:id" component={ModifyTeamScreen} />

          <Route
            path="/admin/allcompetition/:id"
            component={CompetitionStatsScreen}
            exact
          />

          <Route
            path="/new-team"
            component={NewTeamScreen}
            exact
          />

          <Route
            path="/competition-apply/:id"
            component={CompetitionApplyScreen}
            exact
          />

          <Route
            path="/admin/competition-apply-link"
            component={CompetitionApplyLinkScreen}
            exact
          />

          <Route
            path="/competition-apply-find/:id"
            component={CompetitionApplyFindScreen}
            exact
          />

          <Route
            path="/recorder/main"
            component={RecorderMainScreen}
            exact
          />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
